import Store from 'conf-store';
import camelcase from 'camelcase-keys';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config';

const env = process.env.NODE_ENV || 'development';
const store = new Store({
  env,
  production: env === 'production',

  apollo: {
    http: {
      uri: process.env.GRAPHQL_URL || 'https://api.roundhillmusic.net/graphql',
    },
  },

  // Expose TailwindCSS config values
  tw: camelcase(resolveConfig(tailwindConfig).theme, { deep: true }),

  cloudinary: {
    cloudName: process.env.CLOUDINARY_CLOUD_NAME || 'globobeet',
  },

  analytics: {
    ga: 'UA-165699546-1',
    fbq: '2775167476039463',
  },

  sentry: {
    dsn: 'https://a3d8e0cfe0424365b0f1278d4159a47b@o195131.ingest.sentry.io/5225934',
  },

  mediaUrls: {
    s3: 'https://s3-us-east-2.amazonaws.com/rhm-assets',
    imgix: 'https://rhm-assets.imgix.net',
  },

  favicon: '/favicon.png',

  opengraph: {
    url: 'https://blackhillrecords.com',
    image: 'https://s3-us-east-2.amazonaws.com/rhm-assets/u/2020/05/FacebookBanner.jpg',
    title: 'Black Hill Records',
    description:
      'Black Hill Records is a modern music company focused on hard rock genres, utilizing new, forward-thinking methods for effective artist deveopment. With foundations in genuine artistry and true talent, Black Hill Records exists to maintain a relentless commitment to building compelling brands for our artists.',
  },

  shopify: {
    domain: 'absolute-merch-llc.myshopify.com',
    storefrontAccessToken: '3e525d7cd3473f243bcdc0ed979938bd',
  },
});

export default (key, criteria = {}) => store.get(key, { env, ...criteria });
