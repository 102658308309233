module.exports = {
  theme: {
    aspectRatio: {
      none: 0,
      square: 1 / 1,
      '2/1': 2 / 1,
      '5/3': 5 / 3,
      '16/9': 16 / 9,
    },

    fontFamily: {
      display: [
        'akzidenz-grotesk,sans-serif',
        'system-ui',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
      body: [
        'artifexcf-extralight',
        'Georgia',
        'Cambria',
        '"Times New Roman"',
        'Times',
        'serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
    },
    colors: {
      white: '#ffffff',
      black: '#000000',
      gray: {
        light: '#f1f2f2',
        base: '#808285',
        dark: '#3d3c41',
      },
    },

    extend: {
      spacing: {
        '14': '3.5rem',
      },
    },
  },

  variants: {
    opacity: ['focus', 'responsive', 'hover', 'group-hover'],
    textColor: ['focus', 'responsive', 'hover', 'group-hover'],
  },

  plugins: [
    require('tailwindcss-blend-mode')(), // no options to configure
    require('tailwindcss-aspect-ratio'),
  ],
};
